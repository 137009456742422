const Navbar = () => {
  return (  
    <nav className="navbar">
      <h1>
        <a href="/">Home</a>
        <a href="/products">Laser</a>
        <a href="/resume">Resume</a>
        <a href="/game">Game</a>

      </h1>
    </nav>
    );
}
 
export default Navbar;