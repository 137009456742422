import React, { useState } from 'react';

const Product = ({ imageUrl, title }) => {
  console.log(imageUrl)

  const [isExpanded, setIsExpanded] = useState(false);

  const handleImageClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleOverlayClick = () => {
    setIsExpanded(false);
  };

  return (
    <div>
      <div
        style={{
       
          width: '100%',
          maxWidth: '200px',
          maxHeight: '200px',
          position: 'relative',
          cursor: 'pointer',
          zIndex: isExpanded ? 1 : 'auto',
          overflow: 'hidden', // Add overflow: 'hidden' to crop the image

        }}
        onClick={handleImageClick}
      >
        <img
          src={imageUrl}
          alt="Product"
          style={{ width: '100%', height: 'auto' }}

        />
        
      </div>
      <h3>{title}</h3>
      {isExpanded && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 2,
          }}
          onClick={handleOverlayClick}
        >
          <img
            src={imageUrl}
            alt="Product"
            style={{ maxWidth: '90%', maxHeight: '90%', objectFit: 'contain' }}
          />
        </div>
      )}
    </div>
  );
};

export default Product;