import logo from './logo.svg';
import './index.css';
import Navbar from './Navbar';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './Home';
import Products from './Products';
import Resume from './Resume';
import Game from './Game';
import { Helmet } from 'react-helmet';
import CacheBuster from 'react-cache-buster';

function App() {
  return (   
  <div className="hide-cursor">
        <CacheBuster
      currentVersion={1}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    ></CacheBuster>
     <Helmet>
        <title>Richard Falzone</title>
        <meta name="description" content="Richard Falzone website portfolio" />
        
      </Helmet>
    <Navbar />
    
     <BrowserRouter>
            <Routes>
                <Route path="/products" element={<Products/>} />
                <Route path="/resume" element={<Resume/>} />
                <Route path="/game" element={<Game/>} />
                <Route exact path="/" element={<Home/>} />
            </Routes>        
     </BrowserRouter>
  
  </div>
  );
}

export default App;
