import { dividerClasses } from "@mui/material";

const Resume = () => {
    return ( 

        <div className="container">
            <div className="column">

            <h1>
                Work Experience
            </h1>
            <p> 
                <h2>
                <a href="http://www.forcedexposure.com" target="_blank">Forced Exposure</a> - IT Manager - July 2023 - current
                </h2>
                Manages on-prem and cloud based SQL servers in a Windows based environment. <br/>

                Manages internal and external websites hosted on AWS EC2 instance. <br/>

                Writes and maintains custom automation software for accounting and general office tasks. <br/>
                <br/>

            </p>
            <p> 
                <h2>
                    Cards of the Realm - Software Developer September 2021 - April 2023
                </h2>
                <a href="/game">A roguelike deckbuilder available on Steam today.</a>
            </p>
            
            <p> 
                <h2>
                    United Plastic Fabricating - Design Team Lead July 2019 - October 2020
                </h2>
                Created AutoCAD 3d models based on customer specifications for Fire industry water tanks. <br/>
                Lead team in daily meetings to coordinate concurent project status and team communication.
            </p>
            <h1>
                Education
            </h1>
            <p>
            <hr style={{ width: '100%', height: '1px', background: 'black', border: 'none' }} />

                <h2>
                Worcester Polytechnic Institute
                </h2>
                Bachelor of Science in Managment Information Systems
                <br/>
                <h2>
                Brainstation
                </h2>
                Data science boot camp
                <br/>
                <a href="https://github.com/rfalzone/bggpredictor">Created a ML model pipeline to predict user preferences.</a>
                <br/>
                <h2>
                PMP Certified
                </h2>
                <hr style={{ width: '100%', height: '1px', background: 'black', border: 'none' }} />

            </p>
            </div>
        </div>
     );
}
 
export default Resume;