import Product from './Product';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';


const namesList = ["Hex light tower", "LED Wall panel", "Spiral mirror", "Wall Sconce", "Phage lamp", "Wall hangings", "Abstract flower", "Zakim Bridge sign", "Scifi wargaming terrain"]
const GridContainer = styled(Grid)({
    flexGrow: 1,
    maxWidth: 800, // Adjust the maximum width of the grid container as per your requirements
    margin: '0 auto',
  });
  
  const GridItem = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
  });
  
  const Products = () => {
    const numProducts = 9; // Number of products or images in the folder
  
    const getProductImageUrl = (index) => {
        return `${index}.png`;
      };
  
    const productData = Array.from({ length: numProducts }, (_, index) => ({
      imageUrl: getProductImageUrl(index + 1),  
      title: namesList[index],
    }));
  
    return (
       
      <div>
        <div className="centered">
         <p>
            Click to expand
        </p>
        </div>
        <GridContainer container spacing={3}>
          {productData.map((producte, index) => (
            <GridItem item xs={12} sm={4} key={index}>
              <Product imageUrl={producte.imageUrl} title={producte.title} />
            </GridItem>
          ))}
        </GridContainer>
      </div>
    );
  };
 
export default Products;