const Home = () => {
    return ( 
      
        <div className="container">
        <div className="homePage">
      
                <h1>
                    Richard Falzone portfolio site.
                </h1>
                
               
              
        <div className="image-wrapper">
          <img
          src="pic.jpg"
          style={{ width: '300px', height: 'auto'}}

          />
          </div>

      
          <p>
          I have a wide range of interests, side gigs, and skills.  Writing a 100 line python script for a specific task, or laser cutting a perfect-fit tool to fit in a specific space in my home are my favorite types of projects.
            I run a laser cutting business doing contract work and selling uniquely designed furniture and home goods.  I produce animation elements
          for <a href="https://gifograf.com/">Gif-O-Graf</a> in Rhode Island.  I published the game <a href="https://store.steampowered.com/app/2370880/Cards_of_the_Realm/">
            Cards of the Realm on Steam</a>.  I'm currently the IT Manager at
          <a href="www.forcedexposure.com"> Forced Exposure</a>.
          
          </p>
          </div>
          </div>
     );
}
 
export default Home;