const Game = () => {
    return ( 

        <div className="container">
            <div className="column">
            <h1>
            <a href="https://rflz.itch.io/realm-defender-roguelike-deckbuilder">Play the game!</a>
            </h1>
            <h2>
            <a href="https://store.steampowered.com/app/2370880/Cards_of_the_Realm/">Store page</a>
            </h2>
            <p>
                
                Cards of the Realm is a Unity game built in C# using the .NET framework. <br/> <br/>
                Developed using an object oriented approach, the game serializes the game state to a class, allowing the AI to forsee upcoming plays and create a dynamic, reactive play experience
                that challenges the player's tactical thinking.  It employs the .NET framework for serialization of save data between game scenes and between play sessions.  It also uses multithreading for 
                concurrent function execution, primarily surrounding UI animations and effects.
                <br/> <br/>
                The game was built using test driven development, designing test cases then building functions to satisfy those tests.  This ensures robust exception handling and ease of updating the codebase
                without breaking old features as new features were added.  Generative AI was also used during the development of the game to create art and UI assets. 
            </p>
            </div>
        </div>
  

     );
}
 
export default Game;